// Core
import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// Headings
const FullHeading1 = ({ children }) => (
  <h1>{children[0].replace(/(\n)+/g, " ")}</h1>
)
const TextHeading2 = ({ children }) => <p className={`h2`}>{children}</p>
const TextHeading3 = ({ children }) => <p className={`h3`}>{children}</p>

// Widow
function removeWidow(text, minWords = 3) {
  let content = text,
    words = content.split(" ")

  if (words.length >= minWords) {
    words[words.length - 2] +=
      String.fromCharCode(160) + words[words.length - 1]
    words.pop()
    content = words.join(" ")
  }

  return content
}

// Paragraph
const BlockQuote = ({ children }) => <blockquote>{children}</blockquote>
const Text = ({ children }) => <p>{children}</p>
const TextWithoutWidows = ({ children }) => <p>{removeWidow(children[0])}</p>
const Bold = ({ children }) => <strong>{children}</strong>
const TextWithoutPTags = ({ children }) => children

// Links
const ExternalLink = ({ uri, children }) => (
  <a href={uri} target={`_blank`}>
    {children}
  </a>
)

// Images
const Img = node => {
  const { description, file } = node.data.target.fields
  const mimeType = file["en-US"].contentType
  const mimeGroup = mimeType.split("/")[0]

  switch (mimeGroup) {
    case "image":
      return (
        <p>
          <img
            alt={description ? description["en-US"] : null}
            src={file["en-US"].url}
          />
          {description && (
            <span className={`mono`}>{description["en-US"]}</span>
          )}
        </p>
      )
    default:
      return <span></span>
  }
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <FullHeading1>{children}</FullHeading1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <TextHeading2>{children}</TextHeading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <TextHeading3>{children}</TextHeading3>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return <Img {...node} />
    },
    [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
    [INLINES.HYPERLINK]: (node, children) => {
      return <ExternalLink {...node.data}>{children}</ExternalLink>
    },
  },
}

const removeWidowOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <TextWithoutWidows>{children}</TextWithoutWidows>
    ),
  },
}

export default ({ json, removeWidowFromPText = false }) =>
  documentToReactComponents(
    json,
    removeWidowFromPText ? removeWidowOptions : options
  )
