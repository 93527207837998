import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ScrollSection from "../locomotiveScroll/scrollSection"
import styles from "./footer.module.scss"
import Arrow from "./images/donate.inline.svg"
import LAPhilLogo from "./images/LAPhilLogo.inline.svg"
import { SlideMaskText } from "../slideMaskText"

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterContent {
      allContentfulFooter {
        nodes {
          title
          text
          buttonText
          buttonUrl
          supporterImages {
            description
            file {
              url
            }
            title
          }
          copyrightText
        }
      }
    }
  `)

  const {
    title,
    text,
    buttonText,
    buttonUrl,
    supporterImages,
    copyrightText,
  } = data.allContentfulFooter.nodes[0]

  return (
    <ScrollSection>
      <div
        className={styles.footer}
        data-scroll
        data-scroll-call="footer"
        data-scroll-offset="720"
      >
        <div className="container">
          <h3 className={styles.footerTitle}>{title}</h3>

          <p className={styles.footerText}>{text}</p>

          {buttonUrl && buttonText && (
            <a
              href={buttonUrl}
              className={`${styles.footerButton} button button--filled`}
              target="_blank"
            >
              <SlideMaskText text={buttonText} />
              <Arrow />
            </a>
          )}

          {supporterImages && (
            <ul className={styles.footerSupporters}>
              {supporterImages.map(image => (
                <li className={styles.footerSupportersItem} key={image.title}>
                  <a
                    href={image.description}
                    className={styles.footerSupportersLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={image.file.url} alt={image.title} />
                  </a>
                </li>
              ))}
            </ul>
          )}

          <div className={styles.footerLogo}>
            <LAPhilLogo />
          </div>

          <p className={`${styles.footerCopyright} mono`}>
            ©{new Date().getFullYear()} {copyrightText}
          </p>
        </div>
      </div>
    </ScrollSection>
  )
}

export default Footer
